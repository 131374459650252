import { jsx as _jsx } from "react/jsx-runtime";
import { ROUTER_NAMES } from '@/constants/routerNames';
import { Link } from 'react-router-dom';
var NavigationApp = function () {
    var navList = [
        {
            label: 'Home',
            path: ROUTER_NAMES.HOME,
        },
        {
            label: 'Conversations',
            path: ROUTER_NAMES.CONVERSATIONS,
        },
        {
            label: 'Friends',
            path: ROUTER_NAMES.FRIENDS,
        },
        {
            label: 'Groups',
            path: ROUTER_NAMES.GROUPS,
        },
    ];
    return (_jsx("nav", { children: _jsx("ul", { className: "flex gap-3", children: navList.map(function (item) { return (_jsx("li", { className: "px-2", children: _jsx(Link, { to: item.path, className: "cursor-pointer text-black/80 hover:text-black dark:text-white/80 dark:hover:text-white", children: item.label }) }, item.path)); }) }) }));
};
export default NavigationApp;
