var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import className from 'classnames';
import EyeIcon from '@/assets/icons/eye.svg';
import classNames from 'classnames';
var InputField = function (props) {
    var name = props.name, label = props.label, type = props.type, dataCy = props.dataCy, onChange = props.onChange, classNameWrapper = props.classNameWrapper, classNameLabel = props.classNameLabel, classNameInput = props.classNameInput, restProps = __rest(props, ["name", "label", "type", "dataCy", "onChange", "classNameWrapper", "classNameLabel", "classNameInput"]);
    var _a = useState(false), isShowPassword = _a[0], setIsShowPassword = _a[1];
    var inputWrapperClass = className('relative', { 'mt-2': label });
    var eyeClass = className('h-4 w-4', {
        'fill-gray-400 ': !isShowPassword,
        'fill-gray-900': isShowPassword,
    });
    var inputClass = className('input-default', {
        'input-error': restProps === null || restProps === void 0 ? void 0 : restProps.required,
        'bg-light-100 dark:bg-dark-100 text-light-200 dark:text-dark-200 border-light-100 dark:border-dark-100 cursor-not-allowed !shadow-[none]': props.readOnly,
    }, classNameInput);
    var onInputChange = function (e) {
        if (!onChange)
            return;
        onChange(e.target.value);
    };
    return (_jsxs("div", { className: classNameWrapper, children: [_jsx("label", { htmlFor: name, className: classNames('block text-sm font-medium leading-6', classNameLabel), children: label }), _jsxs("div", { className: inputWrapperClass, children: [_jsx("input", __assign({ id: name, name: name, type: isShowPassword ? 'text' : type || 'text', className: inputClass, onChange: onInputChange, "data-cy": dataCy }, restProps)), type === 'password' && (_jsx("button", { type: "button", className: "absolute right-0 h-full px-3 -translate-y-1/2 top-1/2", onClick: function () { return setIsShowPassword(!isShowPassword); }, children: _jsx(EyeIcon, { className: eyeClass }) }))] })] }));
};
export default InputField;
