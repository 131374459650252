var _a;
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { UpdateGroupAction } from '@/interfaces/chat';
var initialState = {
    groups: [],
    showGroupContextMenu: false,
    showEditGroupModal: false,
    points: { x: 0, y: 0 },
    isSavingChanges: false,
};
export var groupsSlice = createSlice({
    name: 'groups',
    initialState: initialState,
    reducers: {
        addGroup: function (state, action) {
            console.log("addGroup reducer: Adding ".concat(action.payload.id, " to state"));
            state.groups.unshift(action.payload);
        },
        updateGroup: function (state, action) {
            console.log('Inside updateGroup');
            var _a = action.payload, type = _a.type, group = _a.group;
            var existingGroup = state.groups.find(function (g) { return g.id === group.id; });
            var index = state.groups.findIndex(function (g) { return g.id === group.id; });
            if (!existingGroup)
                return;
            switch (type) {
                case UpdateGroupAction.NEW_MESSAGE: {
                    console.log('Inside UpdateGroupAction.NEW_MESSAGE');
                    state.groups.splice(index, 1);
                    state.groups.unshift(group);
                    break;
                }
                default: {
                    console.log('Default Case for updateGroup');
                    state.groups[index] = group;
                    break;
                }
            }
        },
        removeGroup: function (state, action) {
            console.log('removeGroup Reducer');
            var group = state.groups.find(function (g) { return g.id === action.payload.id; });
            var index = state.groups.findIndex(function (g) { return g.id === action.payload.id; });
            if (!group)
                return;
            state.groups.splice(index, 1);
        },
        toggleContextMenu: function (state, action) {
            state.showGroupContextMenu = action.payload;
        },
        setSelectedGroup: function (state, action) {
            state.selectedGroupContextMenu = action.payload;
        },
        setContextMenuLocation: function (state, action) {
            state.points = action.payload;
        },
        setShowEditGroupModal: function (state, action) {
            state.showEditGroupModal = action.payload;
        },
        setIsSavingChanges: function (state, action) {
            state.isSavingChanges = action.payload;
        },
        setGroups: function (state, action) {
            state.groups = action.payload;
        },
    },
});
var selectGroups = function (state) { return state.groups.groups; };
var selectGroupId = function (state, id) { return id; };
export var selectGroupById = createSelector([selectGroups, selectGroupId], function (groups, groupId) {
    return groups.find(function (g) { return g.id === groupId; });
});
export var addGroup = (_a = groupsSlice.actions, _a.addGroup), updateGroup = _a.updateGroup, removeGroup = _a.removeGroup, toggleContextMenu = _a.toggleContextMenu, setContextMenuLocation = _a.setContextMenuLocation, setSelectedGroup = _a.setSelectedGroup, setShowEditGroupModal = _a.setShowEditGroupModal, setIsSavingChanges = _a.setIsSavingChanges, setGroups = _a.setGroups;
export default groupsSlice.reducer;
