import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
// import { MdClose } from 'react-icons/md';
import CloseIcon from '@/assets/icons/close.svg';
import { ModalContainer, ModalContentBody, ModalHeader } from '.';
// import { OverlayStyle } from '../../utils/styles';
import { UpdateUserStatusForm } from '@/components/forms/status';
export var UpdatePresenceStatusModal = function (_a) {
    var setShowModal = _a.setShowModal;
    var ref = useRef(null);
    return (_jsx("div", { className: "modal-overlay", ref: ref, children: _jsxs(ModalContainer, { children: [_jsxs(ModalHeader, { children: [_jsx("h2", { children: "Set Custom Status" }), _jsx(CloseIcon, { className: "h-[32px]", onClick: function () { return setShowModal(false); } })] }), _jsx(ModalContentBody, { children: _jsx(UpdateUserStatusForm, { setShowModal: setShowModal }) })] }) }));
};
