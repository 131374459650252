export default {
    set: function (key, data) {
        localStorage.setItem(key, JSON.stringify(data));
    },
    get: function (key) {
        var data = localStorage.getItem(key) || '{}';
        return JSON.parse(data);
    },
    remove: function (key) {
        localStorage.removeItem(key);
    },
    has: function (key) {
        return !!localStorage.getItem(key);
    },
};
