var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {};
export var authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setCredentials: function (state, action) { return (__assign(__assign({}, state), action.payload)); },
        resetCredentials: function () { return initialState; },
    },
});
// Action creators are generated for each case reducer function
export var setCredentials = (_a = authSlice.actions, _a.setCredentials), resetCredentials = _a.resetCredentials;
export default authSlice.reducer;
export var selectCurrentUser = function (state) { return state.auth; };
