var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Peer from 'peerjs';
import { removeFriendRequest } from '@/store/friends';
import { SocketContext } from '@/context/SocketContext';
import { useToast } from '@/hooks/useToast';
import { setCall, setLocalStream, setPeer, setRemoteStream } from '@/store/call';
// import { CallReceiveDialog } from '@/components/calls/CallReceiveDialog';
// import { useVideoCallRejected } from '@/hooks/sockets/useVideoCallRejected';
// import { useVideoCallHangUp } from '@/hooks/sockets/useVideoCallHangUp';
// import { useVideoCallAccept } from '@/hooks/sockets/useVideoCallAccept';
// import { useVideoCall } from '@/hooks/sockets/call/useVideoCall';
// import { useVoiceCall } from '@/hooks/sockets/call/useVoiceCall';
// import { useVoiceCallAccepted } from '@/hooks/sockets/call/useVoiceCallAccepted';
// import { useVoiceCallHangUp } from '@/hooks/sockets/call/useVoiceCallHangUp';
// import { useVoiceCallRejected } from '@/hooks/sockets/call/useVoiceCallRejected';
import UserCheckIcon from '@/assets/icons/user-check.svg';
import { useFriendRequestReceived } from '@/hooks/sockets/friend-requests/useFriendRequestReceived';
import useFriends from '@/hooks/useFriends';
import { useAppSelector } from '@/store/hook';
export var AppPage = function (_a) {
    var children = _a.children;
    var user = useAppSelector(function (state) { return state.auth; });
    var socket = useContext(SocketContext);
    var dispatch = useDispatch();
    var navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _b = useSelector(function (state) { return state.call; }), peer = _b.peer, call = _b.call, isReceivingCall = _b.isReceivingCall, caller = _b.caller, connection = _b.connection, callType = _b.callType;
    var info = useToast({ theme: 'dark' }).info;
    var fetchFriendRequest = useFriends().fetchFriendRequest;
    useEffect(function () {
        fetchFriendRequest();
    }, [dispatch]);
    useEffect(function () {
        if (!user || !user.peerId)
            return;
        var newPeer = new Peer(user.peerId, {
            config: {
                iceServers: [
                    {
                        url: 'stun:stun.l.google.com:19302',
                    },
                    {
                        url: 'stun:stun1.l.google.com:19302',
                    },
                ],
            },
        });
        dispatch(setPeer(newPeer));
    }, []);
    useFriendRequestReceived();
    // useVideoCall();
    useEffect(function () {
        console.log('Registering all events for AppPage');
        socket.on('onFriendRequestCancelled', function (payload) {
            console.log('onFriendRequestCancelled');
            console.log(payload);
            dispatch(removeFriendRequest(payload));
        });
        socket.on('onFriendRequestAccepted', function (payload) {
            console.log('onFriendRequestAccepted');
            dispatch(removeFriendRequest(payload.friendRequest));
            socket.emit('getOnlineFriends');
            info("".concat(payload.friendRequest.receiver.name, " accepted your friend request"), {
                position: 'bottom-left',
                icon: UserCheckIcon,
                onClick: function () { return navigate('/friends'); },
            });
        });
        socket.on('onFriendRequestRejected', function (payload) {
            console.log('onFriendRequestRejected');
            dispatch(removeFriendRequest(payload));
        });
        return function () {
            socket.off('onFriendRequestCancelled');
            socket.off('onFriendRequestRejected');
            socket.off('onFriendRequestReceived');
            socket.off('onFriendRequestAccepted');
        };
    }, [socket, isReceivingCall]);
    /**
     * This useEffect hook is for the user who is receiving the call.
     * So we must dispatch the appropriate actions to set the state
     * for the user receiving the call.
     *
     * The user who is calling will have its own instance of MediaConnection/Call
     */
    useEffect(function () {
        if (!peer)
            return;
        peer.on('call', function (incomingCall) { return __awaiter(void 0, void 0, void 0, function () {
            var constraints, stream;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('Incoming Call!!!!!');
                        console.log(callType);
                        constraints = { video: callType === 'video', audio: true };
                        console.log(constraints);
                        return [4 /*yield*/, navigator.mediaDevices.getUserMedia(constraints)];
                    case 1:
                        stream = _a.sent();
                        console.log('Receiving Call & Got Local Stream:', stream.id);
                        incomingCall.answer(stream);
                        dispatch(setLocalStream(stream));
                        dispatch(setCall(incomingCall));
                        return [2 /*return*/];
                }
            });
        }); });
        return function () {
            peer.off('call');
        };
    }, [peer, callType, dispatch]);
    useEffect(function () {
        if (!call)
            return;
        call.on('stream', function (remoteStream) { return dispatch(setRemoteStream(remoteStream)); });
        call.on('close', function () { return console.log('call was closed'); });
        return function () {
            call.off('stream');
            call.off('close');
        };
    }, [call]);
    // useVideoCallAccept();
    // useVideoCallRejected();
    // useVideoCallHangUp();
    // useVoiceCall();
    // useVoiceCallAccepted();
    // useVoiceCallHangUp();
    // useVoiceCallRejected();
    useEffect(function () {
        if (connection) {
            console.log('connection is defined....');
            if (connection) {
                console.log('connection is defined...');
                socket.on('connect', function () {
                    console.log('Connected');
                });
                connection.on('open', function () {
                    console.log('connection was opened');
                });
                connection.on('error', function () {
                    console.log('an error has occured');
                });
                connection.on('data', function (data) {
                    console.log('data received', data);
                });
                connection.on('close', function () {
                    console.log('connection closed');
                });
            }
            return function () {
                connection === null || connection === void 0 ? void 0 : connection.off('open');
                connection === null || connection === void 0 ? void 0 : connection.off('error');
                connection === null || connection === void 0 ? void 0 : connection.off('data');
            };
        }
    }, [connection]);
    return _jsx(_Fragment, { children: children });
};
