var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { get, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import ERROR_MESSAGES from '@/constants/errorMessage';
import { storageKeys } from '@/constants/storage-keys';
import StorageService from '@/services/local-storage';
import { resetCredentials } from '@/store/auth';
import ERROR_CODE from '@/constants/errorCode';
import { ROUTER_NAMES } from '@/constants/routerNames';
export var baseQuery = fetchBaseQuery({
    baseUrl: process.env.APP_API_ENDPOINT,
    prepareHeaders: function (headers) {
        var _a;
        var accessToken = (_a = StorageService.get(storageKeys.AUTH_PROFILE)) === null || _a === void 0 ? void 0 : _a.accessToken;
        if (accessToken) {
            headers.set('Authorization', "Bearer ".concat(accessToken));
        }
        return headers;
    },
});
export var customBaseQuery = function (args, api, extraOptions) { return __awaiter(void 0, void 0, void 0, function () {
    var body, params, argsCustom, refreshToken, result, refreshResult, _refreshResult, tokens;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                body = args.body instanceof FormData ? args.body : decamelizeKeys(args.body);
                params = decamelizeKeys(args.params);
                argsCustom = __assign(__assign({}, args), { body: body, params: params });
                refreshToken = (_a = StorageService.get(storageKeys.AUTH_PROFILE)) === null || _a === void 0 ? void 0 : _a.refreshToken;
                return [4 /*yield*/, baseQuery(argsCustom, api, extraOptions)];
            case 1:
                result = _d.sent();
                if (!(result.error &&
                    result.error.status === 401 &&
                    ((_c = (_b = result.error.data) === null || _b === void 0 ? void 0 : _b.error) === null || _c === void 0 ? void 0 : _c.code) === ERROR_CODE.AUTH.ACCESS_TOKEN_EXPIRED)) return [3 /*break*/, 9];
                _d.label = 2;
            case 2:
                _d.trys.push([2, , 7, 8]);
                return [4 /*yield*/, baseQuery({ url: '/auth/refresh-token', method: 'POST', body: { refresh_token: refreshToken } }, api, extraOptions)];
            case 3:
                refreshResult = _d.sent();
                if (!refreshResult.data) return [3 /*break*/, 5];
                _refreshResult = camelizeKeys(refreshResult);
                tokens = get(_refreshResult, 'data.result.data', {});
                StorageService.set(storageKeys.AUTH_PROFILE, tokens);
                return [4 /*yield*/, baseQuery(args, api, extraOptions)];
            case 4:
                // retry original query
                result = _d.sent();
                return [3 /*break*/, 6];
            case 5:
                handleNotification(api, result);
                _d.label = 6;
            case 6: return [3 /*break*/, 8];
            case 7: return [7 /*endfinally*/];
            case 8: return [3 /*break*/, 10];
            case 9:
                if (result.error) {
                    handleNotification(api, result);
                }
                _d.label = 10;
            case 10:
                if (result.data) {
                    result.data = camelizeKeys(result.data);
                }
                return [2 /*return*/, result];
        }
    });
}); };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var handleNotification = function (api, result) {
    var _a, _b;
    var errorStatus = result.error.status;
    var error = (_b = (_a = result === null || result === void 0 ? void 0 : result.error) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error;
    var message = '';
    var navigateTo = null;
    // clear profile and token
    if (errorStatus === 401) {
        console.log('clear profile and token');
        api.dispatch(resetCredentials());
        // api.dispatch(logOut())
        StorageService.remove(storageKeys.AUTH_PROFILE);
    }
    switch (errorStatus) {
        case 400:
            message = 'Bad Request';
            break;
        case 401:
            message = 'Unauthorized';
            navigateTo = ROUTER_NAMES.LOGIN;
            break;
        case 403:
            navigateTo = '/403';
            break;
        case 500:
            message = 'Error 500';
            // navigateTo = '/500'
            break;
        // default:
        //   message = ''
        //   navigateTo = null
    }
    if (!window.navigator.onLine) {
        message = 'No internet connection';
    }
    // show notification
    if (!isEmpty(error)) {
        var errorCode = (error === null || error === void 0 ? void 0 : error.code) || '';
        var messageVal = get(ERROR_MESSAGES, errorCode) || 'Something wrong';
        console.log('ERROR:', messageVal);
        toast(messageVal);
    }
    else if (message) {
        console.log('ERROR:', message);
        toast(message);
    }
    // redirect
    // TODO: handle redirect outside react component
    var pathname = window.location.pathname;
    if (pathname !== navigateTo && navigateTo) {
        console.log('redirect:::', "".concat(window.location.origin).concat(navigateTo));
        // eslint-disable-next-line no-debugger
        window.location.href = "".concat(window.location.origin).concat(navigateTo);
    }
};
