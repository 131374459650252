var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    isEditingMessage: false,
    showContextMenu: false,
    points: { x: 0, y: 0 },
};
export var messageContainerSlice = createSlice({
    name: 'messageContainer',
    initialState: initialState,
    reducers: {
        setSelectedMessage: function (state, action) {
            state.selectedMessage = action.payload;
        },
        setMessageBeingEdited: function (state, action) {
            state.messageBeingEdited = action.payload;
        },
        setIsEditing: function (state, action) {
            state.isEditingMessage = action.payload;
        },
        editMessageContent: function (state, action) {
            if (state.messageBeingEdited)
                state.messageBeingEdited.content = action.payload;
        },
        resetMessageContainer: function (state) {
            state.isEditingMessage = false;
            state.messageBeingEdited = undefined;
            state.selectedMessage = undefined;
        },
        toggleContextMenu: function (state, action) {
            state.showContextMenu = action.payload;
        },
        setContextMenuLocation: function (state, action) {
            state.points = action.payload;
        },
    },
});
export var setIsEditing = (_a = messageContainerSlice.actions, _a.setIsEditing), setMessageBeingEdited = _a.setMessageBeingEdited, setSelectedMessage = _a.setSelectedMessage, editMessageContent = _a.editMessageContent, resetMessageContainer = _a.resetMessageContainer, toggleContextMenu = _a.toggleContextMenu, setContextMenuLocation = _a.setContextMenuLocation;
export default messageContainerSlice.reducer;
