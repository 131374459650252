export var EXCEPTION_CODE = {
    AUTH: {
        ACCESS_TOKEN_EXPIRED: 'ACCESS_TOKEN_EXPIRED',
    },
    OAUTH: {
        CODE_NOT_FOUND: 'OAUTH.CODE_NOT_FOUND',
        ERROR: 'OAUTH.ERROR',
    },
    USER: {
        EMAIL_NOT_FOUND: 'USER.EMAIL_NOT_FOUND',
        EMAIL_EXIST: 'USER.EMAIL_EXIST',
        WRONG_PASSWORD: 'USER.WRONG_PASSWORD',
        ID_NOT_FOUND: 'USER.ID_NOT_FOUND',
        ROLE_INVALID: 'USER.ROLE_INVALID',
        INVALID_RESET_TOKEN: 'USER.INVALID_RESET_TOKEN',
    },
    NOTIFICATION: {
        ID_NOT_FOUND: 'NOTIFICATION.ID_NOT_FOUND',
    },
};
export default EXCEPTION_CODE;
