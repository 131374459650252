var _a;
import { createContext } from 'react';
import { io } from 'socket.io-client';
import { storageKeys } from '@/constants/storage-keys';
import StorageService from '@/services/local-storage';
var APP_WEBSOCKET_URL = process.env.APP_WEBSOCKET_URL || 'http://localhost:8080';
var accessToken = ((_a = StorageService.get(storageKeys.AUTH_PROFILE)) === null || _a === void 0 ? void 0 : _a.accessToken) || '';
console.log('APP_WEBSOCKET_URL:', APP_WEBSOCKET_URL);
export var socket = io(APP_WEBSOCKET_URL, {
    transports: ['websocket', 'polling'],
    withCredentials: true,
    extraHeaders: {
        Authorization: "Bearer ".concat(accessToken),
    },
    // parser: customParser // TODO: ???
});
export var SocketContext = createContext(socket);
