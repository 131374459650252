var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createBrowserRouter, redirect, Navigate, } from 'react-router-dom';
import AuthLayout from '@/layouts/AuthLayout';
import MainLayout from '@/layouts/MainLayout';
import { RootErrorBoundary } from '@/pages/Systems/RootErrorBoundary';
import { storageKeys } from '@/constants/storage-keys';
import StorageService from '@/services/local-storage';
import { ROUTER_NAMES } from '@/constants/routerNames';
var setTitlePage = function (title) {
    document.title = 'Pet - ' + title;
};
var protectedLoader = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var isAuthenticated, pathname, params;
    var _c;
    var request = _b.request;
    return __generator(this, function (_d) {
        isAuthenticated = !!((_c = StorageService.get(storageKeys.AUTH_PROFILE)) === null || _c === void 0 ? void 0 : _c.accessToken);
        pathname = new URL(window.location.href).pathname;
        if (!isAuthenticated && pathname !== '/auth/login') {
            params = new URLSearchParams();
            params.set('from', new URL(request.url).pathname);
            return [2 /*return*/, redirect('/auth/login?' + params.toString())];
        }
        return [2 /*return*/, null];
    });
}); };
var loginLoader = function () {
    var _a;
    var isAuthenticated = !!((_a = StorageService.get(storageKeys.AUTH_PROFILE)) === null || _a === void 0 ? void 0 : _a.accessToken);
    var pathname = new URL(window.location.href).pathname;
    if (isAuthenticated && pathname === '/auth/login') {
        return redirect('/');
    }
    return null;
};
var publicRoutes = [
    {
        path: ROUTER_NAMES.AUTH,
        loader: loginLoader,
        Component: AuthLayout,
        children: [
            {
                path: 'login',
                lazy: function () { return import('../pages/Auth/Login'); }, // Single route in lazy file
                loader: function () {
                    setTitlePage('Login');
                    return null;
                },
            },
            {
                path: 'forgot-password',
                lazy: function () { return import('../pages/Auth/ForgotPassword'); },
                loader: function () {
                    setTitlePage('Forgot Password');
                    return null;
                },
            },
            {
                path: 'reset-password',
                lazy: function () { return import('../pages/Auth/ResetPassword'); },
                loader: function () {
                    setTitlePage('Reset Password');
                    return null;
                },
            },
        ],
    },
];
var protectedRoutes = [
    {
        index: true, // Default route
        lazy: function () {
            return __awaiter(this, void 0, void 0, function () {
                var HomePage;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, import('../pages/HomePage')];
                        case 1:
                            HomePage = (_a.sent()).HomePage;
                            return [2 /*return*/, { Component: HomePage }];
                    }
                });
            });
        },
        loader: function () {
            setTitlePage('Home');
            return null;
        },
    },
    {
        path: ROUTER_NAMES.HOME,
        element: _jsx(Navigate, { to: ROUTER_NAMES.ROOT }),
    },
    // Conversations page
    {
        path: ROUTER_NAMES.CONVERSATIONS,
        lazy: function () {
            return __awaiter(this, void 0, void 0, function () {
                var ConversationPage;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, import('../pages/Conversations')];
                        case 1:
                            ConversationPage = (_a.sent()).ConversationPage;
                            return [2 /*return*/, { Component: ConversationPage }];
                    }
                });
            });
        },
        loader: function () {
            setTitlePage('Conversation');
            return null;
        },
        children: [
            {
                path: ':id',
                lazy: function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var ConversationChannelPage;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, import('../pages/Conversations/Detail')];
                                case 1:
                                    ConversationChannelPage = (_a.sent()).ConversationChannelPage;
                                    return [2 /*return*/, { Component: ConversationChannelPage }];
                            }
                        });
                    });
                },
                loader: function () {
                    setTitlePage('Conversation Detail');
                    return null;
                },
            },
        ],
    },
    // Friend pages
    {
        path: ROUTER_NAMES.FRIENDS,
        lazy: function () {
            return __awaiter(this, void 0, void 0, function () {
                var FriendsLayoutPage;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, import('../pages/Friends/FriendsLayoutPage')];
                        case 1:
                            FriendsLayoutPage = (_a.sent()).FriendsLayoutPage;
                            return [2 /*return*/, { Component: FriendsLayoutPage }];
                    }
                });
            });
        },
        loader: function () {
            setTitlePage('Friends');
            return null;
        },
        children: [
            {
                path: ROUTER_NAMES.FRIEND_REQUEST,
                lazy: function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var FriendRequestPage;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, import('../pages/Friends/FriendRequestPage')];
                                case 1:
                                    FriendRequestPage = (_a.sent()).FriendRequestPage;
                                    return [2 /*return*/, { Component: FriendRequestPage }];
                            }
                        });
                    });
                },
                loader: function () {
                    setTitlePage('Friend Request');
                    return null;
                },
            },
            {
                path: ROUTER_NAMES.FRIEND_BLOCKED,
                lazy: function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var FriendRequestBlocked;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, import('../pages/Friends/FriendRequestBlocked')];
                                case 1:
                                    FriendRequestBlocked = (_a.sent()).FriendRequestBlocked;
                                    return [2 /*return*/, { Component: FriendRequestBlocked }];
                            }
                        });
                    });
                },
                loader: function () {
                    setTitlePage('Friend Blocked');
                    return null;
                },
            },
        ],
    },
    // Group pages
    {
        path: ROUTER_NAMES.GROUPS,
        lazy: function () {
            return __awaiter(this, void 0, void 0, function () {
                var GroupPage;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, import('../pages/Groups')];
                        case 1:
                            GroupPage = (_a.sent()).GroupPage;
                            return [2 /*return*/, { Component: GroupPage }];
                    }
                });
            });
        },
        loader: function () {
            setTitlePage('Groups');
            return null;
        },
        children: [
            {
                path: ROUTER_NAMES.GROUP_DETAIL,
                lazy: function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var GroupChannelPage;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, import('../pages/Groups/GroupDetail')];
                                case 1:
                                    GroupChannelPage = (_a.sent()).GroupChannelPage;
                                    return [2 /*return*/, { Component: GroupChannelPage }];
                            }
                        });
                    });
                },
                loader: function () {
                    setTitlePage('Group Detail');
                    return null;
                },
            },
        ],
    },
    // Profile page
    {
        path: ROUTER_NAMES.PROFILE,
        lazy: function () {
            return __awaiter(this, void 0, void 0, function () {
                var ProfilePage;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, import('../pages/Profile')];
                        case 1:
                            ProfilePage = (_a.sent()).ProfilePage;
                            return [2 /*return*/, { Component: ProfilePage }];
                    }
                });
            });
        },
        loader: function () {
            setTitlePage('Profile');
            return null;
        },
        children: [
            {
                path: ':id',
                lazy: function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var ProfilePage;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, import('../pages/Profile')];
                                case 1:
                                    ProfilePage = (_a.sent()).ProfilePage;
                                    return [2 /*return*/, { Component: ProfilePage }];
                            }
                        });
                    });
                },
                loader: function () {
                    setTitlePage('Profile');
                    return null;
                },
            },
        ],
    },
    // ...other protected routes
];
var router = createBrowserRouter([
    {
        id: 'root',
        path: ROUTER_NAMES.ROOT,
        errorElement: _jsx(RootErrorBoundary, {}),
        children: __spreadArray(__spreadArray([
            {
                path: '',
                loader: protectedLoader,
                Component: MainLayout,
                children: protectedRoutes,
            }
        ], publicRoutes, true), [
            {
                path: '*',
                lazy: function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var PageNotFound;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, import('../pages/Systems/PageNotFound')];
                                case 1:
                                    PageNotFound = (_a.sent()).PageNotFound;
                                    return [2 /*return*/, { Component: PageNotFound }];
                            }
                        });
                    });
                },
            },
        ], false),
    },
]);
export default router;
