import PersonKickIcon from '@/assets/icons/person-kick.svg';
import CrowIcon from '@/assets/icons/crown.svg';
import MinusIcon from '@/assets/icons/minus.svg';
import RotateIcon from '@/assets/icons/rotate.svg';
import CommentDotsIcon from '@/assets/icons/comment-dots-regular.svg';
import UserGroupIcon from '@/assets/icons/user-group.svg';
import Gear from '@/assets/icons/gear.svg';
import VideoIcon from '@/assets/icons/video.svg';
import Person from '@/assets/icons/person.svg';
import LockIcon from '@/assets/icons/lock.svg';
import BellIcon from '@/assets/icons/bell.svg';
import InfiniteIcon from '@/assets/icons/infinity.svg';
import PaletteIcon from '@/assets/icons/palette.svg';
import HouseIcon from '@/assets/icons/house.svg';
export var getRecipientFromConversation = function (conversation, user) {
    return (user === null || user === void 0 ? void 0 : user.id) === (conversation === null || conversation === void 0 ? void 0 : conversation.creator.id) ? conversation === null || conversation === void 0 ? void 0 : conversation.recipient : conversation === null || conversation === void 0 ? void 0 : conversation.creator;
};
export var getUserContextMenuIcon = function (type) {
    switch (type) {
        case 'kick':
            return { icon: PersonKickIcon, color: '#ff0000' };
        case 'transfer_owner':
            return { icon: CrowIcon, color: '#FFB800' };
        default:
            return { icon: MinusIcon, color: '#7c7c7c' };
    }
};
export var isGroupOwner = function (user, group) { return (user === null || user === void 0 ? void 0 : user.id) === (group === null || group === void 0 ? void 0 : group.owner.id); };
export var getUserSidebarIcon = function (id) {
    switch (id) {
        case 'home':
            return HouseIcon;
        case 'conversations':
            return CommentDotsIcon;
        case 'friends':
            return UserGroupIcon;
        case 'connections':
            return RotateIcon;
        case 'settings':
            return Gear;
        case 'calls':
            return VideoIcon;
        case 'profile':
            return Person;
        default:
            return CommentDotsIcon;
    }
};
export var getSettingSidebarIcon = function (id) {
    switch (id) {
        case 'profile':
            return Person;
        case 'security':
            return LockIcon;
        case 'notifications':
            return BellIcon;
        case 'integrations':
            return InfiniteIcon;
        case 'appearance':
            return PaletteIcon;
    }
};
export var getFriendRequestDetails = function (_a, user) {
    var receiver = _a.receiver, sender = _a.sender;
    if ((user === null || user === void 0 ? void 0 : user.id) === (receiver === null || receiver === void 0 ? void 0 : receiver.id)) {
        return {
            status: 'Incoming Friend Request',
            displayName: sender.name,
            user: sender,
            incoming: true,
        };
    }
    return {
        status: 'Outgoing Friend Request',
        displayName: receiver.name,
        user: receiver,
        incoming: false,
    };
};
export var getUserFriendInstance = function (authenticatedUser, selectedFriend) {
    return (authenticatedUser === null || authenticatedUser === void 0 ? void 0 : authenticatedUser.id) === (selectedFriend === null || selectedFriend === void 0 ? void 0 : selectedFriend.sender.id)
        ? selectedFriend === null || selectedFriend === void 0 ? void 0 : selectedFriend.receiver
        : selectedFriend === null || selectedFriend === void 0 ? void 0 : selectedFriend.sender;
};
