var _a;
import { createSelector, createSlice } from '@reduxjs/toolkit';
var initialState = {
    conversations: [],
    loading: false,
};
export var conversationsSlice = createSlice({
    name: 'conversations',
    initialState: initialState,
    reducers: {
        addConversation: function (state, action) {
            console.log('addConversation');
            state.conversations.unshift(action.payload);
        },
        updateConversation: function (state, action) {
            console.log('Inside updateConversation');
            var conversation = action.payload;
            var index = state.conversations.findIndex(function (c) { return c.id === conversation.id; });
            state.conversations.splice(index, 1);
            state.conversations.unshift(conversation);
        },
        setConversations: function (state, action) {
            state.conversations = action.payload;
        },
    },
});
var selectConversations = function (state) { return state.conversation.conversations; };
var selectConversationId = function (state, id) { return id; };
export var selectConversationById = createSelector([selectConversations, selectConversationId], function (conversations, conversationId) { return conversations.find(function (c) { return c.id === conversationId; }); });
// Action creators are generated for each case reducer function
export var addConversation = (_a = conversationsSlice.actions, _a.addConversation), updateConversation = _a.updateConversation, setConversations = _a.setConversations;
export default conversationsSlice.reducer;
