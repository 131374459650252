import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation, Link } from 'react-router-dom';
import classNames from 'classnames';
import { getUserSidebarIcon } from '@/helpers';
import { UserAvatar } from '@/components/users/UserAvatar';
import { useAppSelector } from '@/store/hook';
export var UserSidebarItem = function (_a) {
    var item = _a.item;
    var pathname = useLocation().pathname;
    var user = useAppSelector(function (state) { return state.auth; });
    var friendRequests = useAppSelector(function (state) { return state.friends.friendRequests; });
    var Icon = getUserSidebarIcon(item.id);
    var isActive = function () {
        if (pathname.includes('/groups') && item.id === 'conversations')
            return true;
        return pathname === item.pathname;
    };
    return (_jsxs(Link, { to: item.pathname, className: classNames('relative my-1 flex gap-4 rounded-[8px] p-3 hover:bg-[rgba(255,255,255,0.1)] md:w-full', {
            'border border-solid border-[#5d5d5d] dark:border-[#2e2e2e]': isActive(),
        }), "data-tooltip-id": "sidebar-tooltip", "data-tooltip-content": item.title, "data-tooltip-place": 'right', children: [_jsx("div", { className: "flex w-6 items-center justify-center", children: item.id === 'profile' ? (_jsx(UserAvatar, { user: user, className: "!h-auto !w-6" })) : (_jsx(Icon, { className: classNames('w-6 fill-icon-dark opacity-80 hover:opacity-100 dark:fill-icon-light', {
                        '!dark:fill-white !opacity-100': isActive(),
                    }) })) }), _jsx("span", { className: "hidden flex-1 dark:text-[#f5f5f5] xl:block", children: item.title }), item.id === 'friends' && friendRequests.length > 0 && (_jsx("div", { className: "absolute right-1 top-0.5 flex items-center justify-center bg-[#ff3535] text-[10px]", children: friendRequests.length > 9 ? '10+' : friendRequests.length }))] }));
};
