var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    isCalling: false,
    isCallInProgress: false,
    isReceivingCall: false,
};
export var callSlice = createSlice({
    name: 'callSlice',
    initialState: initialState,
    reducers: {
        setIsCalling: function (state, action) {
            state.isCalling = action.payload;
        },
        setPeer: function (state, action) {
            state.peer = action.payload;
        },
        setCall: function (state, action) {
            state.call = action.payload;
        },
        setConnection: function (state, action) {
            state.connection = action.payload;
        },
        setIsReceivingCall: function (state, action) {
            state.isReceivingCall = action.payload;
        },
        setCaller: function (state, action) {
            state.caller = action.payload;
        },
        setReceiver: function (state, action) {
            state.receiver = action.payload;
        },
        setRemoteStream: function (state, action) {
            state.remoteStream = action.payload;
        },
        setLocalStream: function (state, action) {
            state.localStream = action.payload;
        },
        setIsCallInProgress: function (state, action) {
            state.isCallInProgress = action.payload;
            state.isCalling = false;
        },
        setActiveConversationId: function (state, action) {
            state.activeConversationId = action.payload;
        },
        setCallType: function (state, action) {
            state.callType = action.payload;
        },
        resetState: function (state) {
            state.isCalling = false;
            state.isCallInProgress = false;
            state.caller = undefined;
            state.call = undefined;
            state.connection = undefined;
            state.isReceivingCall = false;
            state.remoteStream = undefined;
            state.localStream = undefined;
            state.activeConversationId = undefined;
            state.receiver = undefined;
            state.callType = undefined;
        },
        initiateCallState: function (state, action) { return (__assign(__assign({}, state), action.payload)); },
    },
});
export var setIsCalling = (_a = callSlice.actions, _a.setIsCalling), setPeer = _a.setPeer, setCall = _a.setCall, setConnection = _a.setConnection, setIsReceivingCall = _a.setIsReceivingCall, setCaller = _a.setCaller, setRemoteStream = _a.setRemoteStream, setLocalStream = _a.setLocalStream, setIsCallInProgress = _a.setIsCallInProgress, setActiveConversationId = _a.setActiveConversationId, resetState = _a.resetState, setReceiver = _a.setReceiver, initiateCallState = _a.initiateCallState, setCallType = _a.setCallType;
export default callSlice.reducer;
