export var ROUTER_NAMES = Object.freeze({
    ROOT: '/',
    HOME: '/home',
    AUTH: '/auth',
    LOGIN: '/auth/login',
    LOGOUT: '/logout',
    NOT_FOUND: '/404',
    PROFILE: '/profile',
    CONVERSATIONS: '/conversations',
    CONVERSATIONS_DETAIL: '/conversations/:id',
    FRIENDS: '/friends',
    FRIEND_REQUEST: '/friends/requests',
    FRIEND_BLOCKED: '/friends/blocked',
    GROUPS: '/groups',
    GROUP_DETAIL: '/groups/:id',
});
