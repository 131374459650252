var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    attachments: [],
    attachmentCounter: 0,
};
export var messagePanelSlice = createSlice({
    name: 'messagePanel',
    initialState: initialState,
    reducers: {
        addAttachment: function (state, action) {
            state.attachments.push(action.payload);
        },
        removeAttachment: function (state, action) {
            state.attachments = state.attachments.filter(function (file) { return file.id !== action.payload.id; });
        },
        incrementAttachmentCounter: function (state) {
            state.attachmentCounter++;
        },
        removeAllAttachments: function (state) {
            state.attachments = [];
        },
    },
});
export var addAttachment = (_a = messagePanelSlice.actions, _a.addAttachment), removeAttachment = _a.removeAttachment, incrementAttachmentCounter = _a.incrementAttachmentCounter, removeAllAttachments = _a.removeAllAttachments;
export default messagePanelSlice.reducer;
