import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
export function RootErrorBoundary() {
    var error = useRouteError();
    console.log('RootErrorBoundary:', error);
    // We only care to handle 401's at this level, so if this is not a 401
    // ErrorResponse, re-throw to let the RootErrorBoundary handle it
    if (!isRouteErrorResponse(error) || error.status !== 401) {
        return (_jsx(_Fragment, { children: _jsx("h1", { children: "You do not have access to this project" }) }));
    }
    return (_jsxs("div", { children: [_jsx("h1", { children: "Uh oh, something went terribly wrong \uD83D\uDE29" }), _jsx("pre", { children: error.message || JSON.stringify(error) }), _jsx("button", { onClick: function () { return (window.location.href = '/'); }, children: "Click here to reload the app" })] }));
}
