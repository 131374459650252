var _a;
import { createSelector, createSlice } from '@reduxjs/toolkit';
var initialState = {
    messages: [],
    loading: false,
};
export var messagesSlice = createSlice({
    name: 'messages',
    initialState: initialState,
    reducers: {
        addMessage: function (state, action) {
            var _a = action.payload, conversation = _a.conversation, message = _a.message;
            var conversationMessage = state.messages.find(function (cm) { return cm.id === conversation.id; });
            conversationMessage === null || conversationMessage === void 0 ? void 0 : conversationMessage.messages.unshift(message);
        },
        deleteMessage: function (state, action) {
            console.log('Inside deleteMessage reducer');
            var payload = action.payload;
            var conversationMessages = state.messages.find(function (cm) { return cm.id === payload.conversationId; });
            if (!conversationMessages)
                return;
            var messageIndex = conversationMessages.messages.findIndex(function (m) { return m.id === payload.messageId; });
            conversationMessages.messages.splice(messageIndex, 1);
        },
        editMessage: function (state, action) {
            console.log('editMessageReducer');
            var message = action.payload;
            var conversationMessage = state.messages.find(function (cm) { return cm.id === message.conversation.id; });
            if (!conversationMessage)
                return;
            var messageIndex = conversationMessage.messages.findIndex(function (m) { return m.id === message.id; });
            conversationMessage.messages[messageIndex] = message;
        },
        setMessage: function (state, action) {
            var data = (action === null || action === void 0 ? void 0 : action.payload) || {};
            if (!data)
                return;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            var id = data.id, messages = data.messages;
            var index = state.messages.findIndex(function (cm) { return cm.id === id; });
            var exists = state.messages.find(function (cm) { return cm.id === id; });
            if (exists) {
                console.log('exists');
                state.messages[index] = data;
            }
            else {
                state.messages.push(data);
            }
        },
    },
    // extraReducers: (builder) => {
    //   builder
    //     .addCase(fetchMessagesThunk.fulfilled, (state, action) => {
    //       const { data } = action?.payload || {}
    //       if (!data) return
    //       // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //       const { id, messages } = data
    //       const index = state.messages.findIndex((cm) => cm.id === id)
    //       const exists = state.messages.find((cm) => cm.id === id)
    //       if (exists) {
    //         console.log('exists')
    //         state.messages[index] = data
    //       } else {
    //         state.messages.push(data)
    //       }
    //     })
    //     .addCase(deleteMessageThunk.fulfilled, (state, action) => {
    //       const { data } = action?.payload || {}
    //       if (!data) return
    //       const conversationMessages = state.messages.find((cm) => cm.id === data.conversationId)
    //       if (!conversationMessages) return
    //       const messageIndex = conversationMessages.messages.findIndex((m) => m.id === data.messageId)
    //       conversationMessages?.messages.splice(messageIndex, 1)
    //     })
    //     .addCase(editMessageThunk.fulfilled, (state, action) => {
    //       console.log('editMessageThunk.fulfilled')
    //       const { data: message } = action?.payload || {}
    //       if (!message) return
    //       // const { data: message } = action.payload;
    //       const { id } = message.conversation
    //       const conversationMessage = state.messages.find((cm) => cm.id === id)
    //       if (!conversationMessage) return
    //       const messageIndex = conversationMessage.messages.findIndex((m) => m.id === message.id)
    //       console.log(messageIndex)
    //       conversationMessage.messages[messageIndex] = message
    //       console.log('Updated Message')
    //     })
    // },
});
var selectConversationMessages = function (state) { return state.messages.messages; };
var selectConversationMessageId = function (state, id) { return id; };
export var selectConversationMessage = createSelector([selectConversationMessages, selectConversationMessageId], function (conversationMessages, id) { return conversationMessages.find(function (cm) { return cm.id === id; }); });
export var addMessage = (_a = messagesSlice.actions, _a.addMessage), deleteMessage = _a.deleteMessage, editMessage = _a.editMessage, setMessage = _a.setMessage;
export default messagesSlice.reducer;
