var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { get } from 'lodash';
import { CDN_URL } from '@/enums/chat';
import defaultAvatar from '@/assets/images/default_avatar.jpg';
export var UserAvatar = function (_a) {
    var _b = _a.user, user = _b === void 0 ? {} : _b, className = _a.className, onClick = _a.onClick, props = __rest(_a, ["user", "className", "onClick"]);
    var getProfilePicture = function () {
        var avatarUrl = get(user, 'profile.avatar'); // TODO: get avatar url
        return avatarUrl ? CDN_URL.BASE.concat(avatarUrl) : defaultAvatar;
    };
    return (_jsx("img", __assign({ className: classNames('h-[44px] w-[44px] cursor-pointer rounded-[50%]', className), src: getProfilePicture(), alt: "avatar", onClick: onClick }, props)));
};
