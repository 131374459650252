var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    friends: [],
    friendRequests: [],
    onlineFriends: [],
    offlineFriends: [],
    showContextMenu: false,
    points: { x: 0, y: 0 },
};
export var friendsSlice = createSlice({
    name: 'friends',
    initialState: initialState,
    reducers: {
        setFriends: function (state, action) {
            state.friends = action.payload;
        },
        setFriendRequests: function (state, action) {
            state.friendRequests = action.payload;
        },
        addFriendRequest: function (state, action) {
            state.friendRequests.push(action.payload);
        },
        removeFriendRequest: function (state, action) {
            var id = action.payload.id;
            state.friendRequests = state.friendRequests.filter(function (friendRequest) { return friendRequest.id !== id; });
        },
        removeFriend: function (state, action) {
            console.log('removeFriend reducer');
            state.friends = state.friends.filter(function (friend) { return friend.id !== action.payload.id; });
        },
        setOnlineFriends: function (state, action) {
            console.log('setFriends Reducer');
            state.onlineFriends = action.payload;
        },
        setOfflineFriends: function (state) {
            console.log('setOfflineFriends Reducer');
            console.log(state.onlineFriends);
            state.offlineFriends = state.friends.filter(function (friend) { return !state.onlineFriends.find(function (onlineFriend) { return onlineFriend.id === friend.id; }); });
        },
        toggleContextMenu: function (state, action) {
            state.showContextMenu = action.payload;
        },
        setSelectedFriend: function (state, action) {
            state.selectedFriendContextMenu = action.payload;
        },
        setContextMenuLocation: function (state, action) {
            state.points = action.payload;
        },
    },
    // extraReducers: (builder) =>
    //   builder
    //     .addCase(fetchFriendsThunk.fulfilled, (state, action) => {
    //       console.log('fetchFriendsThunk.fulfilled')
    //       const { data = [] } = action?.payload || {}
    //       console.log(data)
    //       state.friends = data
    //     })
    //     .addCase(fetchFriendRequestThunk.fulfilled, (state, action) => {
    //       console.log('fetchFriendRequestsThunk.fulfilled')
    //       const { data = [] } = action?.payload || {}
    //       state.friendRequests = data
    //     })
    //     .addCase(createFriendRequestThunk.fulfilled, (state, action) => {
    //       console.log('createFriendRequestThunk.fulfilled')
    //       const { data } = action?.payload || {}
    //       if (!data) return
    //       state.friendRequests.push(data)
    //     })
    //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //     .addCase(createFriendRequestThunk.rejected, (state, action) => {
    //       console.log('createFriendRequestThunk.rejected')
    //     })
    //     .addCase(cancelFriendRequestThunk.fulfilled, (state, action) => {
    //       const { data } = action?.payload || {}
    //       if (!data) return
    //       const { id } = data
    //       state.friendRequests = state.friendRequests.filter(
    //         (friendRequest) => friendRequest.id !== id,
    //       )
    //     })
    //     .addCase(acceptFriendRequestThunk.fulfilled, (state, action) => {
    //       console.log('acceptFriendRequestThunk.fulfilled')
    //       const { data } = action?.payload || {}
    //       if (!data) return
    //       const {
    //         friendRequest: { id },
    //       } = data
    //       state.friendRequests = state.friendRequests.filter(
    //         (friendRequest) => friendRequest.id !== id,
    //       )
    //     })
    //     .addCase(rejectFriendRequestThunk.fulfilled, (state, action) => {
    //       console.log('rejectFriendRequestThunk.fulfilled')
    //       const { data } = action?.payload || {}
    //       if (!data) return
    //       const { id } = data
    //       state.friendRequests = state.friendRequests.filter(
    //         (friendRequest) => friendRequest.id !== id,
    //       )
    //     })
    //     .addCase(removeFriendThunk.fulfilled, (state, action) => {
    //       console.log('rejectFriendRequestThunk.fulfilled')
    //       const { data } = action?.payload || {}
    //       if (!data) return
    //       state.friends = state.friends.filter((friend) => friend.id !== data.id)
    //     }),
});
export var setFriends = (_a = friendsSlice.actions, _a.setFriends), setFriendRequests = _a.setFriendRequests, addFriendRequest = _a.addFriendRequest, removeFriendRequest = _a.removeFriendRequest, setOnlineFriends = _a.setOnlineFriends, setOfflineFriends = _a.setOfflineFriends, toggleContextMenu = _a.toggleContextMenu, setContextMenuLocation = _a.setContextMenuLocation, setSelectedFriend = _a.setSelectedFriend, removeFriend = _a.removeFriend;
export default friendsSlice.reducer;
