import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    type: 'private',
};
export var selectedTypeApi = createSlice({
    name: 'selectedType',
    initialState: initialState,
    reducers: {
        updateType: function (state, action) {
            state.type = action.payload;
        },
    },
});
export var selectType = function (state) { return state.selectedConversationType.type; };
export var updateType = selectedTypeApi.actions.updateType;
export default selectedTypeApi.reducer;
