var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useAppDispatch } from '@/store/hook';
import { addFriendRequest, setFriends, setFriendRequests, removeFriendRequest, } from '@/store/friends';
import { useLazyFriendRequestsQuery, useLazyFriendListQuery, useCreateFriendMutation, useCancelFriendMutation, useAcceptFriendMutation, useRejectFriendMutation, useRemoveFriendMutation, } from '@/services/friend';
var useFriends = function () {
    var dispatch = useAppDispatch();
    var createFriendService = useCreateFriendMutation()[0];
    var friendList = useLazyFriendListQuery()[0];
    var _a = useLazyFriendRequestsQuery(), friendRequestsList = _a[0], isLoadingFriendRequest = _a[1].isLoading;
    var cancelFriendRequestService = useCancelFriendMutation()[0];
    var acceptFriend = useAcceptFriendMutation()[0];
    var rejectFriend = useRejectFriendMutation()[0];
    var removeFriendService = useRemoveFriendMutation()[0];
    var fetchFriends = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, friendsData, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, friendList().unwrap()];
                case 1:
                    res = _b.sent();
                    friendsData = (_a = res.result) === null || _a === void 0 ? void 0 : _a.data;
                    if (!friendsData)
                        return [2 /*return*/];
                    dispatch(setFriends(friendsData));
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _b.sent();
                    console.log('Fetch friend error', error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var fetchFriendRequest = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, friendRequestData, error_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, friendRequestsList().unwrap()];
                case 1:
                    res = _b.sent();
                    friendRequestData = (_a = res.result) === null || _a === void 0 ? void 0 : _a.data;
                    if (!friendRequestData)
                        return [2 /*return*/];
                    dispatch(setFriendRequests(friendRequestData));
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _b.sent();
                    console.log('Fetch friend request error', error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var createFriendRequest = function (request_id) { return __awaiter(void 0, void 0, void 0, function () {
        var res, newRequest, error_3;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, createFriendService({ request_id: request_id }).unwrap()];
                case 1:
                    res = _b.sent();
                    newRequest = (_a = res.result) === null || _a === void 0 ? void 0 : _a.data;
                    if (!newRequest)
                        return [2 /*return*/];
                    dispatch(addFriendRequest(newRequest));
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _b.sent();
                    console.log('Create friend request error', error_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var cancelFriendRequest = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var res, data, error_4;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, cancelFriendRequestService(id).unwrap()];
                case 1:
                    res = _b.sent();
                    data = (_a = res.result) === null || _a === void 0 ? void 0 : _a.data;
                    if (!data)
                        return [2 /*return*/];
                    dispatch(removeFriendRequest({ id: id }));
                    return [3 /*break*/, 3];
                case 2:
                    error_4 = _b.sent();
                    console.log('Cancel friend request error', error_4);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var acceptFriendRequest = function (requestId) { return __awaiter(void 0, void 0, void 0, function () {
        var res, data, error_5;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, acceptFriend(requestId).unwrap()];
                case 1:
                    res = _b.sent();
                    data = (_a = res.result) === null || _a === void 0 ? void 0 : _a.data;
                    if (!data)
                        return [2 /*return*/];
                    dispatch(addFriendRequest(data.friendRequest));
                    return [3 /*break*/, 3];
                case 2:
                    error_5 = _b.sent();
                    console.log('Accept friend request error', error_5);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var rejectFriendRequest = function (requestId) { return __awaiter(void 0, void 0, void 0, function () {
        var res, data, error_6;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, rejectFriend(requestId).unwrap()];
                case 1:
                    res = _b.sent();
                    data = (_a = res.result) === null || _a === void 0 ? void 0 : _a.data;
                    if (!data)
                        return [2 /*return*/];
                    dispatch(removeFriendRequest({ id: requestId }));
                    return [3 /*break*/, 3];
                case 2:
                    error_6 = _b.sent();
                    console.log('Reject friend request error', error_6);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var removeFriend = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var res, data, error_7;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, removeFriendService(id).unwrap()];
                case 1:
                    res = _b.sent();
                    data = (_a = res.result) === null || _a === void 0 ? void 0 : _a.data;
                    if (!data)
                        return [2 /*return*/];
                    dispatch(removeFriendRequest({ id: id }));
                    return [3 /*break*/, 3];
                case 2:
                    error_7 = _b.sent();
                    console.log('Remove friend error', error_7);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return {
        isLoadingFriendRequest: isLoadingFriendRequest,
        createFriendRequest: createFriendRequest,
        fetchFriends: fetchFriends,
        fetchFriendRequest: fetchFriendRequest,
        cancelFriendRequest: cancelFriendRequest,
        acceptFriendRequest: acceptFriendRequest,
        rejectFriendRequest: rejectFriendRequest,
        removeFriend: removeFriend,
    };
};
export default useFriends;
