import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useNavigate } from 'react-router-dom';
import NavigationApp from '@/components/Navigation';
import { storageKeys } from '@/constants/storage-keys';
import StorageService from '@/services/local-storage';
import { ROUTER_NAMES } from '@/constants/routerNames';
import { useAppDispatch, useAppSelector } from '@/store/hook';
import { resetCredentials } from '@/store/auth';
import LogoIcon from '@/assets/icons/logo.svg';
import ThemeSwitch from '../ThemeSwitch';
var Header = function () {
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var isAuthenticated = useAppSelector(function (state) { return !!state.auth.role; });
    var user = useAppSelector(function (state) { return state.auth; });
    var handleLogout = function () {
        console.log('Logout');
        StorageService.set(storageKeys.AUTH_PROFILE, null);
        dispatch(resetCredentials());
        navigate(ROUTER_NAMES.LOGIN);
    };
    return (_jsxs("header", { className: "flex items-center justify-between border-b border-solid border-gray-300 bg-white px-5 py-3 shadow-xl dark:border-none dark:bg-[#282829] dark:text-white", children: [_jsx(Link, { to: ROUTER_NAMES.HOME, children: _jsx(LogoIcon, { className: "h-10 fill-gray-800 dark:fill-white" }) }), isAuthenticated && (_jsx(_Fragment, { children: _jsx(NavigationApp, {}) })), _jsxs("div", { className: "flex", children: [_jsx(ThemeSwitch, {}), isAuthenticated && (_jsxs("div", { className: "flex gap-2", children: [_jsx("span", { children: (user === null || user === void 0 ? void 0 : user.name) || '' }), _jsx("button", { onClick: handleLogout, children: "Logout" })] }))] })] }));
};
export default Header;
