var _a;
import { createSelector, createSlice } from '@reduxjs/toolkit';
var initialState = {
    messages: [],
};
export var groupMessagesSlice = createSlice({
    name: 'groupMessages',
    initialState: initialState,
    reducers: {
        addGroupMessage: function (state, action) {
            var _a = action.payload, group = _a.group, message = _a.message;
            var groupMessage = state.messages.find(function (gm) { return gm.id === group.id; });
            groupMessage === null || groupMessage === void 0 ? void 0 : groupMessage.messages.unshift(message);
        },
        editGroupMessage: function (state, action) {
            console.log('editGroupMessageThunk.fulfilled');
            var payload = action.payload;
            var id = payload.group.id;
            var groupMessage = state.messages.find(function (gm) { return gm.id === id; });
            if (!groupMessage)
                return;
            var messageIndex = groupMessage.messages.findIndex(function (m) { return m.id === payload.id; });
            console.log(messageIndex);
            groupMessage.messages[messageIndex] = payload;
            console.log('Updated Message');
        },
        setGroupMessage: function (state, action) {
            var data = action.payload;
            var id = data.id;
            console.log('setGroupMessage', data);
            var index = state.messages.findIndex(function (gm) { return gm.id === id; });
            var exists = state.messages.find(function (gm) { return gm.id === id; });
            exists ? (state.messages[index] = data) : state.messages.push(data);
        },
        deleteGroupMessage: function (state, action) {
            var data = (action === null || action === void 0 ? void 0 : action.payload) || {};
            if (!data)
                return;
            var groupMessages = state.messages.find(function (gm) { return gm.id === data.groupId; });
            console.log(data);
            console.log(groupMessages);
            if (!groupMessages)
                return;
            var messageIndex = groupMessages.messages.findIndex(function (m) { return m.id === data.messageId; });
            groupMessages === null || groupMessages === void 0 ? void 0 : groupMessages.messages.splice(messageIndex, 1);
        },
    },
});
var selectGroupMessages = function (state) { return state.groupMessages.messages; };
var selectGroupMessageId = function (state, id) { return id; };
export var selectGroupMessage = createSelector([selectGroupMessages, selectGroupMessageId], function (groupMessages, id) { return groupMessages.find(function (gm) { return gm.id === id; }); });
export var addGroupMessage = (_a = groupMessagesSlice.actions, _a.addGroupMessage), editGroupMessage = _a.editGroupMessage, setGroupMessage = _a.setGroupMessage, deleteGroupMessage = _a.deleteGroupMessage;
export default groupMessagesSlice.reducer;
