import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '@/services/base';
export var conversationsApi = createApi({
    baseQuery: customBaseQuery,
    reducerPath: 'conversationsApi',
    tagTypes: ['Conversations'],
    endpoints: function (builder) { return ({
        checkConversationOrCreate: builder.query({
            query: function (recipientId) { return ({
                url: "/exists/conversations/".concat(recipientId),
                method: 'GET',
            }); },
        }),
        getConversations: builder.query({
            query: function () { return ({
                url: '/conversations',
                method: 'GET',
            }); },
        }),
        getConversationsById: builder.query({
            query: function (id) { return ({
                url: "/conversations/".concat(id),
                method: 'GET',
            }); },
        }),
        getConversationMessages: builder.query({
            query: function (conversationId) { return ({
                url: "/conversations/".concat(conversationId, "/messages"),
                method: 'GET',
            }); },
        }),
        createMessage: builder.mutation({
            query: function (_a) {
                var id = _a.id, type = _a.type, data = _a.data;
                var url = type === 'private' ? "/conversations/".concat(id, "/messages") : "/groups/".concat(id, "/messages");
                return {
                    url: url,
                    method: 'POST',
                    body: data,
                };
            },
        }),
        postNewConversation: builder.mutation({
            query: function (data) { return ({
                url: '/conversations',
                method: 'POST',
                body: data,
            }); },
        }),
        deleteMessage: builder.mutation({
            query: function (_a) {
                var id = _a.id, messageId = _a.messageId;
                return ({
                    url: "/conversations/".concat(id, "/messages/").concat(messageId),
                    method: 'DELETE',
                });
            },
        }),
        editMessage: builder.mutation({
            query: function (_a) {
                var id = _a.id, messageId = _a.messageId, content = _a.content;
                return ({
                    url: "/conversations/".concat(id, "/messages/").concat(messageId),
                    method: 'PATCH',
                    body: { content: content },
                });
            },
        }),
    }); },
});
export var useLazyCheckConversationOrCreateQuery = conversationsApi.useLazyCheckConversationOrCreateQuery, useLazyGetConversationsQuery = conversationsApi.useLazyGetConversationsQuery, useLazyGetConversationsByIdQuery = conversationsApi.useLazyGetConversationsByIdQuery, useLazyGetConversationMessagesQuery = conversationsApi.useLazyGetConversationMessagesQuery, useCreateMessageMutation = conversationsApi.useCreateMessageMutation, usePostNewConversationMutation = conversationsApi.usePostNewConversationMutation, useDeleteMessageMutation = conversationsApi.useDeleteMessageMutation, useEditMessageMutation = conversationsApi.useEditMessageMutation;
