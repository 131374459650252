import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '@/services/base';
export var friendApi = createApi({
    baseQuery: customBaseQuery,
    reducerPath: 'friendApi',
    tagTypes: ['Friend'],
    endpoints: function (builder) { return ({
        friendRequests: builder.query({
            query: function () { return ({
                url: '/friends/requests',
                method: 'GET',
            }); },
        }),
        friendList: builder.query({
            query: function () { return ({
                url: '/friends',
                method: 'GET',
            }); },
        }),
        createFriend: builder.mutation({
            query: function (data) { return ({
                url: '/friends/requests',
                method: 'POST',
                body: data,
            }); },
        }),
        cancelFriend: builder.mutation({
            query: function (id) { return ({
                url: "/friends/requests/".concat(id, "/cancel"),
                method: 'DELETE',
            }); },
        }),
        acceptFriend: builder.mutation({
            query: function (id) { return ({
                url: "/friends/requests/".concat(id, "/accept"),
                method: 'PATCH',
            }); },
        }),
        rejectFriend: builder.mutation({
            query: function (id) { return ({
                url: "/friends/requests/".concat(id, "/reject"),
                method: 'PATCH',
            }); },
        }),
        removeFriend: builder.mutation({
            query: function (id) { return ({
                url: "/friends/".concat(id, "/delete"),
                method: 'DELETE',
            }); },
        }),
    }); },
});
export var useLazyFriendRequestsQuery = friendApi.useLazyFriendRequestsQuery, useLazyFriendListQuery = friendApi.useLazyFriendListQuery, useCreateFriendMutation = friendApi.useCreateFriendMutation, useCancelFriendMutation = friendApi.useCancelFriendMutation, useAcceptFriendMutation = friendApi.useAcceptFriendMutation, useRejectFriendMutation = friendApi.useRejectFriendMutation, useRemoveFriendMutation = friendApi.useRemoveFriendMutation;
