var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    messages: [],
    messageCounter: 0,
};
export var systemMessagesSlice = createSlice({
    name: 'systemMessages',
    initialState: initialState,
    reducers: {
        addSystemMessage: function (state, action) {
            state.messageCounter++;
            state.messages.push(action.payload);
        },
        clearAllMessages: function (state) {
            state.messages = [];
        },
    },
});
export var addSystemMessage = (_a = systemMessagesSlice.actions, _a.addSystemMessage), clearAllMessages = _a.clearAllMessages;
export default systemMessagesSlice.reducer;
