import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import '@/styles/index.scss';
import '@/styles/tailwind.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import router from '@/router';
import useProfile from '@/hooks/useProfile';
import LoadingPage from '@/components/LoadingPage';
import { currentTheme } from '@/store/theme';
import { useAppSelector } from '@/store/hook';
var App = function () {
    var fetchProfile = useProfile().fetchProfile;
    var theme = useAppSelector(currentTheme);
    var isProfileFetched = useAppSelector(function (state) { return !!state.auth.role; });
    useEffect(function () {
        if (isProfileFetched)
            return;
        fetchProfile();
    }, []);
    return (_jsxs("div", { className: "app h-full", children: [_jsx(RouterProvider, { router: router, fallbackElement: _jsx(LoadingPage, {}) }), _jsx(ToastContainer, { position: "top-right", autoClose: 5000, theme: theme })] }));
};
export default App;
