import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '@/services/base';
// Define a service using a base URL and expected endpoints
export var oauthApi = createApi({
    baseQuery: customBaseQuery,
    reducerPath: 'oauthApi',
    tagTypes: ['Oauth'],
    endpoints: function (builder) { return ({
        oauthGoogle: builder.query({
            query: function (params) { return ({
                url: '/oauth/google',
                method: 'GET',
                params: params,
            }); },
        }),
        oauthLogin: builder.query({
            query: function () { return ({
                url: '/oauth/google/guard',
                method: 'GET',
            }); },
        }),
    }); },
});
export var useLazyOauthGoogleQuery = oauthApi.useLazyOauthGoogleQuery, useLazyOauthLoginQuery = oauthApi.useLazyOauthLoginQuery;
