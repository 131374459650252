export var CDN_URL;
(function (CDN_URL) {
    CDN_URL["BASE"] = "https://chuachat.ams3.cdn.digitaloceanspaces.com/";
    CDN_URL["ORIGINAL"] = "https://chuachat.ams3.cdn.digitaloceanspaces.com/original/";
    CDN_URL["PREVIEW"] = "https://chuachat.ams3.digitaloceanspaces.com/preview/";
})(CDN_URL || (CDN_URL = {}));
export var WebsocketEvents;
(function (WebsocketEvents) {
    WebsocketEvents["VOICE_CALL_ACCEPTED"] = "onVoiceCallAccepted";
    WebsocketEvents["VOICE_CALL_HANG_UP"] = "onVoiceCallHangUp";
    WebsocketEvents["VOICE_CALL_REJECTED"] = "onVoiceCallRejected";
    WebsocketEvents["VIDEO_CALL_REJECTED"] = "onVideoCallRejected";
})(WebsocketEvents || (WebsocketEvents = {}));
export var SenderEvents;
(function (SenderEvents) {
    SenderEvents["VIDEO_CALL_INITIATE"] = "onVideoCallInitiate";
    SenderEvents["VIDEO_CALL_ACCEPT"] = "videoCallAccepted";
    SenderEvents["VOICE_CALL_INITIATE"] = "onVoiceCallInitiate";
    SenderEvents["VOICE_CALL_ACCEPT"] = "onVoiceCallAccepted";
})(SenderEvents || (SenderEvents = {}));
export var ReceiverEvents;
(function (ReceiverEvents) {
    ReceiverEvents["VOICE_CALL"] = "onVoiceCall";
})(ReceiverEvents || (ReceiverEvents = {}));
export var EConversationType;
(function (EConversationType) {
    EConversationType["PRIVATE"] = "private";
    EConversationType["GROUP"] = "group";
})(EConversationType || (EConversationType = {}));
