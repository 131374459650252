var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    showSidebar: true,
    showUserContextMenu: false,
    points: { x: 0, y: 0 },
};
export var groupRecipientSidebarSlice = createSlice({
    name: 'groupRecipientSidebarSlice',
    initialState: initialState,
    reducers: {
        toggleSidebar: function (state) {
            state.showSidebar = !state.showSidebar;
        },
        toggleContextMenu: function (state, action) {
            state.showUserContextMenu = action.payload;
        },
        setSelectedUser: function (state, action) {
            state.selectedUser = action.payload;
        },
        setContextMenuLocation: function (state, action) {
            state.points = action.payload;
        },
    },
});
export var setContextMenuLocation = (_a = groupRecipientSidebarSlice.actions, _a.setContextMenuLocation), setSelectedUser = _a.setSelectedUser, toggleSidebar = _a.toggleSidebar, toggleContextMenu = _a.toggleContextMenu;
export default groupRecipientSidebarSlice.reducer;
