var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { toast } from 'react-toastify';
export function useToast(defaultOptions) {
    if (defaultOptions === void 0) { defaultOptions = { theme: 'dark' }; }
    var success = function (data) { return toast(data, __assign(__assign({}, defaultOptions), { type: 'success' })); };
    var error = function (data, options) {
        return toast(data, __assign(__assign(__assign({}, defaultOptions), options), { type: 'error' }));
    };
    var info = function (data, options) {
        return toast(data, __assign(__assign(__assign({}, defaultOptions), options), { type: 'info' }));
    };
    return { success: success, error: error, info: info };
}
