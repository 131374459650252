import { customBaseQuery } from '@/services/base';
import { createApi } from '@reduxjs/toolkit/query/react';
export var userApi = createApi({
    baseQuery: customBaseQuery,
    reducerPath: 'userApi',
    tagTypes: ['User'],
    endpoints: function (builder) { return ({
        getList: builder.query({
            query: function () { return ({
                url: '/users',
                method: 'GET',
            }); },
        }),
        getUser: builder.query({
            query: function (userId) { return ({
                url: "/users/".concat(userId),
                method: 'GET',
            }); },
        }),
        updateUser: builder.mutation({
            query: function (_a) {
                var id = _a.id, data = _a.data;
                return ({
                    url: "/users/".concat(id),
                    method: 'PATCH',
                    body: data,
                });
            },
        }),
        search: builder.query({
            query: function (query) { return ({
                url: "/users/search?query=".concat(query),
                method: 'GET',
            }); },
        }),
        updateStatusMessage: builder.mutation({
            query: function (data) { return ({
                url: '/users/presence/status',
                method: 'PATCH',
                body: data,
            }); },
        }),
    }); },
});
export var useLazyGetListQuery = userApi.useLazyGetListQuery, useLazyGetUserQuery = userApi.useLazyGetUserQuery, useUpdateUserMutation = userApi.useUpdateUserMutation, useLazySearchQuery = userApi.useLazySearchQuery, useUpdateStatusMessageMutation = userApi.useUpdateStatusMessageMutation;
