var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { ETheme } from '@/enums/theme';
var initialState = {
    theme: ETheme.LIGHT,
};
export var themeSlice = createSlice({
    name: 'theme',
    initialState: initialState,
    reducers: {
        setTheme: function (state, action) { return (__assign(__assign({}, state), { theme: action.payload })); },
        resetTheme: function () { return initialState; },
    },
});
export default themeSlice.reducer;
export var setTheme = (_a = themeSlice.actions, _a.setTheme), resetTheme = _a.resetTheme;
export var currentTheme = function (state) { return state.theme.theme; };
