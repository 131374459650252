import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addFriendRequest } from '@/store/friends';
import { SocketContext } from '@/context/SocketContext';
import { useToast } from '@/hooks/useToast';
import UserAdd from '@/assets/icons/user-plus-solid.svg';
export function useFriendRequestReceived() {
    var socket = useContext(SocketContext);
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var info = useToast({ theme: 'dark' }).info;
    useEffect(function () {
        socket.on('onFriendRequestReceived', function (payload) {
            console.log('onFriendRequestReceived');
            console.log(payload);
            dispatch(addFriendRequest(payload));
            info("Incoming Friend Request from ".concat(payload.sender.name), {
                position: 'bottom-left',
                icon: UserAdd,
                onClick: function () { return navigate('/friends/requests'); },
            });
        });
        return function () {
            socket.off('onFriendRequestReceived');
        };
    }, []);
}
