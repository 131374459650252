import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '@/services/base';
export var groupApi = createApi({
    baseQuery: customBaseQuery,
    reducerPath: 'groupApi',
    tagTypes: ['Group'],
    endpoints: function (builder) { return ({
        groupList: builder.query({
            query: function () { return ({
                url: '/groups',
                method: 'GET',
            }); },
        }),
        getGroup: builder.query({
            query: function (id) { return ({
                url: "/groups/".concat(id),
                method: 'GET',
            }); },
        }),
        getGroupMessage: builder.query({
            query: function (id) { return ({
                url: "/groups/".concat(id, "/messages"),
                method: 'GET',
            }); },
        }),
        postGroupMessage: builder.mutation({
            query: function (_a) {
                var id = _a.id, content = _a.content;
                return ({
                    url: "/groups/".concat(id, "/messages"),
                    method: 'POST',
                    body: content,
                });
            },
        }),
        searchUsers: builder.query({
            query: function (query) { return ({
                url: "/user/search?query=".concat(query),
                method: 'GET',
            }); },
        }),
        createGroup: builder.mutation({
            query: function (data) { return ({
                url: '/groups',
                method: 'POST',
                body: data,
            }); },
        }),
        deleteGroupMessage: builder.mutation({
            query: function (_a) {
                var id = _a.id, messageId = _a.messageId;
                return ({
                    url: "/groups/".concat(id, "/messages/").concat(messageId),
                    method: 'DELETE',
                });
            },
        }),
        editGroupMessage: builder.mutation({
            query: function (_a) {
                var id = _a.id, messageId = _a.messageId, content = _a.content;
                return ({
                    url: "/groups/".concat(id, "/messages/").concat(messageId),
                    method: 'PATCH',
                    body: content,
                });
            },
        }),
        addGroupRecipient: builder.mutation({
            query: function (_a) {
                var id = _a.id, add_user_id = _a.add_user_id;
                return ({
                    url: "/groups/".concat(id, "/recipients"),
                    method: 'POST',
                    body: { add_user_id: add_user_id },
                });
            },
        }),
        removeGroupRecipient: builder.mutation({
            query: function (_a) {
                var id = _a.id, userId = _a.userId;
                return ({
                    url: "/groups/".concat(id, "/recipients/").concat(userId),
                    method: 'DELETE',
                });
            },
        }),
        updateGroupOwner: builder.mutation({
            query: function (_a) {
                var id = _a.id, newOwnerId = _a.newOwnerId;
                return ({
                    url: "/groups/".concat(id, "/owner"),
                    method: 'PATCH',
                    body: { newOwnerId: newOwnerId },
                });
            },
        }),
        leaveGroup: builder.mutation({
            query: function (id) { return ({
                url: "/groups/".concat(id, "/leave"),
                method: 'DELETE',
            }); },
        }),
        updateGroupDetails: builder.mutation({
            query: function (_a) {
                var id = _a.id, data = _a.data;
                return ({
                    url: "/groups/".concat(id, "/details"),
                    method: 'PATCH',
                    body: data,
                });
            },
        }),
        fetchGroupMessages: builder.query({
            query: function (id) { return ({
                url: "/groups/".concat(id, "/messages"),
                method: 'GET',
            }); },
        }),
    }); },
});
export var useLazyGroupListQuery = groupApi.useLazyGroupListQuery, useLazyGetGroupQuery = groupApi.useLazyGetGroupQuery, useLazyGetGroupMessageQuery = groupApi.useLazyGetGroupMessageQuery, useLazySearchUsersQuery = groupApi.useLazySearchUsersQuery, useCreateGroupMutation = groupApi.useCreateGroupMutation, useDeleteGroupMessageMutation = groupApi.useDeleteGroupMessageMutation, useEditGroupMessageMutation = groupApi.useEditGroupMessageMutation, useAddGroupRecipientMutation = groupApi.useAddGroupRecipientMutation, useRemoveGroupRecipientMutation = groupApi.useRemoveGroupRecipientMutation, useUpdateGroupOwnerMutation = groupApi.useUpdateGroupOwnerMutation, useLeaveGroupMutation = groupApi.useLeaveGroupMutation, useUpdateGroupDetailsMutation = groupApi.useUpdateGroupDetailsMutation, useLazyFetchGroupMessagesQuery = groupApi.useLazyFetchGroupMessagesQuery;
