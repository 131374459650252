import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useUpdateStatusMessageMutation } from '@/services/user';
import { useAppSelector } from '@/store/hook';
import { useToast } from '@/hooks/useToast';
import { InputField, Button } from '@/components/Form';
export var UpdateUserStatusForm = function (_a) {
    var _b;
    var setShowModal = _a.setShowModal;
    var user = useAppSelector(function (state) { return state.auth; });
    var _c = useToast({ theme: 'dark' }), success = _c.success, error = _c.error;
    var _d = useState(((_b = user === null || user === void 0 ? void 0 : user.presence) === null || _b === void 0 ? void 0 : _b.statusMessage) || ''), statusMessage = _d[0], setStatusMessage = _d[1];
    var updateStatusMessage = useUpdateStatusMessageMutation()[0];
    var saveStatus = function (e) {
        e.preventDefault();
        console.log('Updating Status...');
        updateStatusMessage({ statusMessage: statusMessage })
            .then(function () {
            success('Updated Status!');
            setShowModal(false);
        })
            .catch(function (err) {
            console.log(err);
            error('Failed to Update Status');
        });
    };
    return (_jsxs("form", { className: "w-full", onSubmit: saveStatus, children: [_jsx(InputField, { label: "Message", value: statusMessage, onChange: setStatusMessage }), _jsx("div", { className: "mt-2.5 flex justify-end", children: _jsx(Button, { type: "submit", children: "Save" }) })] }));
};
