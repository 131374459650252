import { customBaseQuery } from '@/services/base';
import { createApi } from '@reduxjs/toolkit/query/react';
export var testApi = createApi({
    baseQuery: customBaseQuery,
    reducerPath: 'testApi',
    tagTypes: ['Test'],
    endpoints: function (builder) { return ({
        testUnauthenticated: builder.query({
            query: function () { return ({
                url: 'test/unauthenticated',
                method: 'GET',
            }); },
        }),
    }); },
});
export var useLazyTestUnauthenticatedQuery = testApi.useLazyTestUnauthenticatedQuery;
