import { jsx as _jsx } from "react/jsx-runtime";
// import {
//   ModalContainerStyle,
//   ModalContentBodyStyle,
//   ModalHeaderStyle,
// } from '../../utils/styles';
export var ModalHeader = function (_a) {
    var children = _a.children;
    return (_jsx("header", { className: "mt-5 flex w-full items-center justify-between px-[24px]", children: children }));
};
export var ModalContentBody = function (_a) {
    var children = _a.children;
    return _jsx("div", { className: "relative p-6", children: children });
};
export var ModalContainer = function (_a) {
    var children = _a.children, showModal = _a.showModal;
    console.log("showModal: ".concat(showModal));
    return (_jsx("div", { className: "relative w-[650px] rounded-[10px] bg-white dark:bg-[#1f1f1f]", children: children }));
};
