export var friendsNavbarItems = [
    {
        id: 'friends',
        label: 'Friends',
        pathname: '/friends',
    },
    {
        id: 'requests',
        label: 'Requests',
        pathname: '/friends/requests',
    },
    {
        id: 'blocked',
        label: 'Blocked',
        pathname: '/friends/blocked',
    },
];
export var chatTypes = [
    {
        type: 'private',
        label: 'Direct Message',
    },
    {
        type: 'group',
        label: 'Group',
    },
];
export var settingsItems = [
    {
        id: 'profile',
        label: 'Profile',
        pathname: '/settings/profile',
    },
    {
        id: 'security',
        label: 'Security',
        pathname: '/settings/security',
    },
    {
        id: 'notifications',
        label: 'Notifications',
        pathname: '/settings/notifications',
    },
    {
        id: 'integrations',
        label: 'Integrations',
        pathname: '/settings/integrations',
    },
    {
        id: 'appearance',
        label: 'Appearance',
        pathname: '/settings/appearance',
    },
];
export var userSidebarItems = [
    {
        id: 'home',
        pathname: '/',
        title: 'Home',
    },
    {
        id: 'conversations',
        pathname: '/conversations',
        title: 'Messages',
    },
    {
        id: 'friends',
        pathname: '/friends',
        title: 'Friends',
    },
    {
        id: 'profile',
        pathname: '/profile',
        title: 'Profile',
    },
    // {
    //   id: 'connections',
    //   pathname: '/connections',
    // },
    // {
    //   id: 'settings',
    //   pathname: '/settings',
    // },
    // {
    //   id: 'calls',
    //   pathname: '/calls',
    // },
];
