var _a;
import { combineReducers, configureStore } from '@reduxjs/toolkit';
// import logger from 'redux-logger'
// stores
import authReducer from './auth';
import themeReducer from './theme';
import friendsReducer from './friends';
import groupsReducer from './group';
import conversationsReducer from './conversations';
import selectedTypeReducer from './selectedType';
import messageReducer from './messages';
import messagePanelReducer from './message-panel';
import systemMessagesReducer from './system-messages';
import callReducer from './call';
import messageContainerReducer from './messageContainer';
import groupSidebarReducer from './groupRecipientsSidebar';
import groupMessagesReducer from './groupMessage';
// services
import { testApi } from '@/services/test-service';
import { authApi } from '@/services/auth';
import { oauthApi } from '@/services/oauth';
import { friendApi } from '@/services/friend';
import { groupApi } from '@/services/group';
import { conversationsApi } from '@/services/conversations';
import { userApi } from '@/services/user';
var rootReducer = combineReducers((_a = {},
    // services
    _a[authApi.reducerPath] = authApi.reducer,
    _a[testApi.reducerPath] = testApi.reducer,
    _a[oauthApi.reducerPath] = oauthApi.reducer,
    _a[friendApi.reducerPath] = friendApi.reducer,
    _a[groupApi.reducerPath] = groupApi.reducer,
    _a[conversationsApi.reducerPath] = conversationsApi.reducer,
    _a[userApi.reducerPath] = userApi.reducer,
    // reducers
    _a.auth = authReducer,
    _a.theme = themeReducer,
    _a.friends = friendsReducer,
    _a.groups = groupsReducer,
    _a.conversation = conversationsReducer,
    _a.selectedConversationType = selectedTypeReducer,
    _a.messages = messageReducer,
    _a.messagePanel = messagePanelReducer,
    _a.systemMessages = systemMessagesReducer,
    _a.call = callReducer,
    _a.messageContainer = messageContainerReducer,
    _a.groupSidebar = groupSidebarReducer,
    _a.groupMessages = groupMessagesReducer,
    _a));
export var setupStore = function (preloadedState) {
    return configureStore({
        reducer: rootReducer,
        middleware: function (getDefaultMiddleware) {
            return getDefaultMiddleware()
                .concat(authApi.middleware)
                .concat(testApi.middleware)
                .concat(oauthApi.middleware)
                .concat(friendApi.middleware)
                .concat(groupApi.middleware)
                .concat(conversationsApi.middleware)
                .concat(userApi.middleware);
        },
        // .concat(logger),
        preloadedState: preloadedState,
    });
};
