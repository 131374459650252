var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
/**
 * Primary UI component for user interaction
 */
var Button = function (props) {
    var _a = props.type, type = _a === void 0 ? 'button' : _a, _b = props.primary, primary = _b === void 0 ? false : _b, _c = props.size, size = _c === void 0 ? 'medium' : _c, backgroundColor = props.backgroundColor, label = props.label, className = props.className, dataCy = props.dataCy, children = props.children, restProps = __rest(props, ["type", "primary", "size", "backgroundColor", "label", "className", "dataCy", "children"]);
    var buttonStyles = classNames({
        'btn-default': !primary,
        'btn-primary': primary,
        'btn-text': type === 'text',
        'px-3 py-[2px] text-[12px]': size === 'small',
        'px-5 py-[4px] text-sm': size === 'medium',
        'px-7 py-[6px] text-[16px]': size === 'large',
    }, className);
    return (_jsxs("button", __assign({ type: type === 'text' ? 'button' : type, className: buttonStyles, style: { backgroundColor: backgroundColor }, "data-cy": dataCy }, restProps, { children: [label, children] })));
};
export default Button;
